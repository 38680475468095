// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { reduxForm, change, reset, type InjectedFormProps } from "redux-form/dist/redux-form";
import mailcheck from "mailcheck";

import { Container, Row, Col } from "kit/grid";
import DateService from "core/DateService";
import { createFormAction } from "core/FormSagaProvider";
import FormProvider, { type FormActionType } from "core/FormProvider.tsx";
import { Icon, Copy, DateFormat, Button } from "kit/ui";
import { Validation } from "kit/helpers";
import { InputField, CheckboxField, DatePickerField } from "kit/form";
import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

import spainDniValidator from "../../../helpers/spainDniValidator";
import COUNTRY from "../../../constants/COUNTRY";

import EmailValidator from "../../../components/EmailValidator/EmailValidator.component";

import routesObject from "../../../routesObject";


import * as styles from "./Register.Signup.styles.js";

const FORM_NAME = "registerSignupForm";


const validate = (values, props) => ({
  password2: Validation.sameAs(values.password)(values.password2)
});
// const asyncValidate = (values) => Promise.reject({ emailAddress: "Invalid e-mail" });

const birthDateMax = DateService.lib().add(-18, "years")
  .startOf("day")
  .toDate();
const birthDateMin = DateService.lib().add(-75, "years")
  .startOf("day")
  .toDate();

const emailValidator = email => {
  if (!email) return Promise.resolve();
  const [ mailAddress, domain ] = email.split("@");
  if ((/(\.)\.*\1/g).test(mailAddress)) {
    return Promise.resolve(`${mailAddress
      .split("")
      .filter(c => c !== ".")
      .join("")}@${domain}`);
  }

  return new Promise((resolve, reject) => {
    mailcheck.run({
      email,
      suggested: suggestion => resolve(decodeURIComponent(suggestion.full)),
      empty: () => resolve(null)
    });
  });
};

const asyncValidate = (values) => {
  return emailValidator(values.emailAddress)
    .then(res => {
      if (res !== null) {
        // eslint-disable-next-line no-throw-literal
        throw { emailAddress: `Email no válido (Quizás quisiste decir: ${res})` };
      }
    });
};


@FormProvider
@reduxForm({
  form: FORM_NAME,
  validate,
  asyncValidate,
  asyncChangeFields: [ "emailAddress" ],
  fields: [
    "firstName",
    "lastName",
    "motherMaidenName",
    "emailAddress",
    "nationalID",
    "birthDate",
    "mobilePhone",
    "password",
    "password2",
    "acceptPolicy",
    "acceptAgreement"
  ]
})
class SignupForm extends PureComponent<InjectedFormProps> {

  static NAME = FORM_NAME;
  static ACTIONS: FormActionType;
  static PREFIX = "##form/REGISTER/SIGNUP";

  static contextTypes = {};

  constructor(props, context) {
    super(props, context);
  }

  validators = {
    required: Validation.required(),
    email: Validation.emailWithAccent(),
    /* dni: Validation.pattern(COUNTRY.patterns.dni), */
    /*     dniLogic: val => {
      const { type, valid } = spainDniValidator(val);
      if (valid) return undefined;
      return "Por favor, ingresá un DNI/NIE válido.";
    }, */
    birthday: Validation.dateValid("YYYY-MM-DD"),
    birthdayRange: Validation.dateRange(birthDateMin, birthDateMax),
    /* phone: Validation.pattern(COUNTRY.patterns.phone), */
    password: Validation.pattern(COUNTRY.patterns.password, "Tu contraseña debe tener entre 4 y 20 caracteres."),
    nameValidCharacters: (str = "") => {
      const allValid = _.every(
        _.split(str, "")
          .map(letter => {
            const isValid = _.includes(COUNTRY.validNameSurnameCharacters, letter);
            return isValid;
          })
      );
      return allValid ? undefined : "Solo se permiten caracteres del alfabeto español.";
    }
  };

  handleOpenPrivacyPolicyModal = () => this.context.modal.open("asd");

  handleToggleSelectAll = (e) => {
    this.props.dispatch(change(SignupForm.NAME, "acceptAgreement", e.target.checked));
    this.props.dispatch(change(SignupForm.NAME, "acceptPolicy", e.target.checked));
  };


  render() {
    const {
      handleSubmit,
      // initialValues,
      // pristine,
      // invalid,
      // valid,
      submitting,
      // anyTouched,
      // dirty,
      // pure,
      // submitFailed,
      // submitSucceeded,
      // error,
      asyncValidating,
      // initialized,
      fields: {
        firstName,
        lastName,
        motherMaidenName,
        emailAddress,
        nationalID,
        birthDate,
        mobilePhone,
        password,
        password2,
        acceptPolicy,
        acceptAgreement
      },
      dispatch,
      formErrors,
      formValues,
      formMeta,
      handleReset,
      handleClear,
      formSubmit,
      formAction
    } = this.props;

    return (

      <form
        name={FORM_NAME}
        id={FORM_NAME}
        onSubmit={handleSubmit(formAction)}
        noValidate>


        <InputField
          name="mobilePhone"
          label="Tel&eacute;fono Celular"
          validate={[
            this.validators.required
            /* this.validators.phone */
          ]}
          digitsOnly
          phone
          fieldProps={{}}
          inputProps={{
            required: true,
            minLength: COUNTRY.phoneMinLength,
            maxLength: COUNTRY.phoneMaxLength,
            type: "tel",
            pattern: "[0-9]*"
          }}
          {...mobilePhone} />

        <InputField
          name="firstName"
          label="Nombre"
          validate={[
            this.validators.required,
            this.validators.nameValidCharacters
          ]}
          inputProps={{
            required: true
          }}
          fieldProps={{}}
          {...firstName} />

        <InputField
          name="lastName"
          label="Apellidos Paterno"
          validate={[
            this.validators.required,
            this.validators.nameValidCharacters
          ]}
          help={(
            <>
              <Icon name="exclamation-circle" size="16" css={SPACES.marRight5} />
              Escribe tus nombres y apellidos tal como aparecen en tu CURP.
            </>
          )}
          fieldProps={{}}
          inputProps={{
            required: true
          }}
          {...lastName} />

        <InputField
          name="motherMaidenName"
          label="Apellidos Materno"
          validate={[
            this.validators.required,
            this.validators.nameValidCharacters
          ]}
          help={(
            <>
              <Icon name="exclamation-circle" size="16" css={SPACES.marRight5} />
              Escribe tus nombres y apellidos tal como aparecen en tu CURP.
            </>
          )}
          fieldProps={{}}
          inputProps={{
            required: true
          }}
          {...motherMaidenName} />

        <InputField
          name="nationalID"
          label="CURP"
          validate={[
            this.validators.required
            /*             this.validators.dni,
            this.validators.dniLogic */
          ]}
          fieldProps={{}}
          inputProps={{
            required: true,
            minLength: COUNTRY.dniMinLength,
            maxLength: COUNTRY.dniMaxLength
          }}
          {...nationalID} />

        {/* <EmailValidator email={formValues.emailAddress}>
          {suggestion => (
            <InputField
              name="emailAddress"
              label="Correo electr&oacute;nico"
              validate={[
                this.validators.required,
                this.validators.email,
                ...suggestion ? [ Validation.returnValidationResult(false, "email", "Test") ] : []
              ]}
              normalize={_.trim}
              fieldProps={{}}
              inputProps={{
                required: true
              }}
              {...emailAddress} />
          )}
        </EmailValidator> */}

        <InputField
          name="emailAddress"
          label="Correo electr&oacute;nico"
          validate={[
            this.validators.required,
            this.validators.email
          ]}
          normalize={_.trim}
          fieldProps={{}}
          inputProps={{
            required: true
          }}
          {...emailAddress} />

        <DatePickerField
          name="birthDate"
          label="Fecha de nacimiento"
          fieldProps={{}}
          datePickerProps={{
            minDate: birthDateMin,
            maxDate: birthDateMax,
            showMonthDropdown: true,
            showYearDropdown: true,
            dropdownMode: "select",
            peekNextMonth: true
          }}
          inputProps={{
            required: true
          }}
          validate={[
            this.validators.required,
            this.validators.birthday,
            this.validators.birthdayRange
          ]}
          {...birthDate} />

        <InputField
          name="password"
          label="Contrase&ntilde;a"
          fieldProps={{}}
          inputProps={{
            required: true,
            type: "password",
            minLength: 4,
            maxLength: 20
          }}
          validate={[
            this.validators.required,
            this.validators.password
          ]}
          {...password} />

        <InputField
          name="password2"
          label="Confirma Contrase&ntilde;a"
          fieldProps={{}}
          inputProps={{
            required: true,
            type: "password",
            minLength: 4,
            maxLength: 20
          }}
          validate={[
            this.validators.required,
            this.validators.password
          ]}
          {...password2} />

        <CheckboxField.Checkbox input={{ checked: formValues.acceptAgreement && formValues.acceptPolicy,
          onChange: this.handleToggleSelectAll  }} meta={{}}>
          <span css={styles.acceptAllCheckbox}>
            ACEPTAR TODO
          </span>
        </CheckboxField.Checkbox>

        <CheckboxField
          name="acceptAgreement"
          validate={[]}
          fieldProps={{}}
          {...acceptAgreement}>
          <span css={{ fontSize: "12px" }}>
            Acepto recibir comunicaciones de marketing sobre productos y ofertas de myKredit o Trive Credit, a partir de la información de contacto que proporcioné.
          </span>
        </CheckboxField>

        <CheckboxField
          name="acceptPolicy"
          validate={[
            this.validators.required
          ]}
          fieldProps={{
            style: {
              marginBottom: 0
            }
          }}
          {...acceptPolicy}>
          <span css={{ fontSize: "12px" }}>
            He leído y acepto la <a style={{
              display: "inline"
            }} target="_blank" href={routesObject.privacyPolicy.url}>Política de Privacidad</a>. Al enviar esta información, acepto el uso de mis datos personales para verificar mi identidad y realizar una evaluación de crédito.
          </span>
        </CheckboxField>

        <div css={styles.infoField}>
          <span>
            <br /> Responsable: Trive Credit Spain, S.L., o myKredit
            <br /> Finalidad: Concesión de micropréstamos
            <br /> Derechos: Acceso, rectificatión, modificación, cancelación, supresión, oposición al tratamiento, revocación, limitación o el ejercicio a la portabilidad de los datos. <br />
            <br /> Para más información, puede consultar la <a target="_blank" href={routesObject.privacyPolicy.url}>Política de Privacidad</a>.
          </span>
        </div>

        <div>
          <Button
            id={`btn-${FORM_NAME}`}
            type="submit"
            fluid
            theme="orange"
            disabled={submitting || asyncValidating}
            loading={submitting || asyncValidating}
            content="Crear mi cuenta" />
        </div>

      </form>
    );

  }
}


SignupForm.propTypes = {
  formAction: PropTypes.object,
  anyTouched: PropTypes.bool,
  asyncValidating: PropTypes.any,
  dirty: PropTypes.bool,
  fields: PropTypes.array,
  dispatch: PropTypes.func,
  formErrors: PropTypes.object,
  formMeta: PropTypes.object,
  formValues: PropTypes.object,
  handleReset: PropTypes.func,
  handleClear: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  initialized: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  pure: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool
};

// SignupForm.contextTypes = {};


export default SignupForm;
