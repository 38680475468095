// @ts-nocheck

import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { type MemberDetailsStateType } from "@myk";
import { Button, Icon, CountDownTimer } from "kit/ui";

import MemberContext from "../../../contexts/MemberContext";

import COUNTRY from "../../../constants/COUNTRY";
import OTP_PURPOSES from "../../../constants/OTP_PURPOSES";

import PinForm from "./Otp.Pin.form";

import * as styles from "../Otp.styles.js";



// type ComponentPropsType = RouteComponentProps & {
//   actions: Object & otpActions.ActionsPropTypes;
//   state: Object & otpActions.StatePropTypes;
// } & RouteComponentProps;


class OtpCard extends Component {
  static contextTypes = {};
  constructor(props) {
    super(props);
  }



  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {}
  // shouldComponentUpdate(nextProps, nextState, nextContext) {}
  // UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {}
  // componentDidUpdate(prevProps, prevState, prevContext)
  // componentWillUnmount() {}

  render() {

    const {
      canRequestNewPin,
      otpSendingInProgress,
      toggleRequestingNewPinToTrue,
      onRequestNewPin,
      changePhoneButton,
      newPhone,
      purpose
    } = this.props;
    // const {} = this.context;
    // const {} = this.state;

    // const {} = actions;
    return (
      <div css={styles.base}>
        {
          [ OTP_PURPOSES.default, OTP_PURPOSES.login ].includes(purpose) ?
            <div css={styles.greenBar}>
              <Icon name="myk shield" />
              <p>¡Mantén tu contraseña segura!</p>
            </div> : null
        }
        <div css={styles.content}>
          <p css={styles.title}>
            Verifica tu n&uacute;mero de celular
          </p>
          <MemberContext.Consumer>
            {
              (member: MemberDetailsStateType) =>
                <p css={styles.desc({ purpose })}>
                  {
                    purpose === OTP_PURPOSES.default ? (
                      <>
                        Introduce el c&oacute;digo de verificaci&oacute;n enviado al
                      </>
                    ) : purpose === OTP_PURPOSES.login ? (
                      <>
                        Por favor, ingresa el código enviado a tu celular para acceder a tu área personal.
                      </>
                    ) : purpose === OTP_PURPOSES.loginWithRepayToken ? null : (
                      <>
                        Parece que no has verificado tu n&uacute;mero m&oacute;vil por un tiempo. Para continuar con el proceso de tu pr&eacute;stamo por favor verifícalo introduciendo en el &aacute;rea de abajo el c&oacute;digo enviado al
                      </>
                    )
                  }
                  <b>
                    &nbsp;
                    {purpose === OTP_PURPOSES.login ? null : purpose === OTP_PURPOSES.loanRequestChangePhoneNumber ? newPhone : newPhone }
                  </b>.
                </p>
            }
          </MemberContext.Consumer>
          <PinForm changePhoneButton={changePhoneButton}>
            {
              !_.isNil(canRequestNewPin) && !canRequestNewPin && (
                <>
                  <p css={styles.requestNewPin}>
                    No recibí el PIN
                  </p>
                  {/* <ProgressBar duration="120" /> */}
                  <p css={styles.requestNewPinDesc}>
                    Puedes solicitar un nuevo PIN en
                    {" "}
                    <CountDownTimer
                      duration={COUNTRY.pinRequestWaitDuration}
                      onComplete={toggleRequestingNewPinToTrue} />
                  </p>
                </>
              )
            }
            {
              !_.isNil(canRequestNewPin) && canRequestNewPin && (
                <div css={styles.requestNewPinButton}>
                  <Button
                    id="btn-otpRequestNewPin"
                    size="small"
                    onClick={onRequestNewPin}
                    loading={otpSendingInProgress}
                    disabled={otpSendingInProgress}
                    outline
                    theme="primary"
                    content="No recibí el PIN" />
                </div>
              )
            }
          </PinForm>
        </div>
      </div>
    );
  }
}

OtpCard.propTypes = {
  canRequestNewPin: PropTypes.bool,
  otpSendingInProgress: PropTypes.bool,
  toggleRequestingNewPinToTrue: PropTypes.func,
  onRequestNewPin: PropTypes.func,
  changePhoneButton: PropTypes.node,
  newPhone: PropTypes.string,
  purpose: PropTypes.string
};

OtpCard.contextTypes = {
};

export default OtpCard;
