// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Global, css } from "@emotion/react";
import { reduxForm, change, reset, type InjectedFormProps } from "redux-form/dist/redux-form";

import { InputField, SelectField, TextAreaField, CheckboxField, RadioField } from "kit/form";
import { createFormAction } from "core/FormSagaProvider";
import FormProvider, { type FormActionType } from "core/FormProvider.tsx";
import { Button, Icon, Copy, DateFormat } from "kit/ui";
import { Validation } from "kit/helpers";

import COUNTRY from "../../constants/COUNTRY";

import * as styles from "./UpdatePasswordModal.styles.js";

const FORM_NAME = "sharedUpdatePasswordForm";


const validate = (values, props) => ({
  password2: Validation.sameAs(values.password)(values.password2)
});
// const asyncValidate = (values) => Promise.reject({ emailAddress: "Invalid e-mail" });


type ComponentPropTypes = {}


@FormProvider
@reduxForm({
  form: FORM_NAME,
  enableReinitialize: false,
  validate,
  // asyncValidate,
  fields: [
    "password",
    "password2"
  ]
})
class UpdatePasswordForm extends PureComponent<InjectedFormProps & ComponentPropTypes> {

  static NAME = FORM_NAME;
  static ACTIONS: FormActionType;
  static PREFIX = "##form/SHARED/UPDATE_PASSWORD_MODAL";
  static MODAL = "updatePasswordModal";


  static contextTypes = {
    modal: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  validators = {
    required: Validation.required(),
    password: Validation.pattern(COUNTRY.patterns.password, "Tu contraseña debe tener entre 4 y 20 caracteres.")
  };

  handleCloseModal = () =>
    this.context.modal.close(UpdatePasswordForm.MODAL);

  render() {
    const {
      handleSubmit,
      // initialValues,
      // pristine,
      // invalid,
      // valid,
      submitting,
      // anyTouched,
      // dirty,
      // pure,
      // submitFailed,
      // submitSucceeded,
      // error,
      asyncValidating,
      // initialized,
      fields: {
        password,
        password2
      },
      dispatch,
      formErrors,
      formValues,
      formMeta,
      handleReset,
      handleClear,
      formSubmit,
      formAction
    } = this.props;


    return (

      <form
        name={FORM_NAME}
        id={FORM_NAME}
        onSubmit={handleSubmit(formAction)}
        noValidate>

        <InputField
          name="password"
          label="Nueva Contrase&ntilde;a"
          help="Tu contraseña debe tener entre 4 y 20 caracteres."
          fieldProps={{}}
          inputProps={{
            required: true,
            type: "password",
            minLength: 4,
            maxLength: 20
          }}
          validate={[
            this.validators.required,
            this.validators.password
          ]}
          {...password} />

        <InputField
          name="password2"
          label="Confirma Nueva Contrase&ntilde;a"
          fieldProps={{}}
          inputProps={{
            required: true,
            type: "password",
            minLength: 4,
            maxLength: 20
          }}
          validate={[
            this.validators.required,
            this.validators.password
          ]}
          {...password2} />

        <div css={{ display: "flex" }}>
          <Button
            style={{ marginRight: 5 }}
            css={styles.btnText}
            onClick={this.handleCloseModal}
            outline
            size="small"
            theme="primary"
            type="button"
            disabled={submitting || asyncValidating}
            loading={submitting || asyncValidating}>
            Cancelar
          </Button>
          <Button
            id={`btn-${FORM_NAME}`}
            css={styles.btnText}
            fluid
            size="small"
            theme="orange"
            type="submit"
            disabled={submitting || asyncValidating}
            loading={submitting || asyncValidating}>
            Guardar cambios
          </Button>
        </div>

      </form>
    );

  }

}


UpdatePasswordForm.propTypes = {
  formAction: PropTypes.object,
  anyTouched: PropTypes.bool,
  asyncValidating: PropTypes.any,
  dirty: PropTypes.bool,
  fields: PropTypes.array,
  dispatch: PropTypes.func,
  formErrors: PropTypes.object,
  formMeta: PropTypes.object,
  formValues: PropTypes.object,
  handleReset: PropTypes.func,
  handleClear: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  initialized: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  pure: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool
};

// UpdatePasswordForm.contextTypes = {};


export default UpdatePasswordForm;
