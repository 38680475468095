// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import uuid from "uuid/v4";
import { Link } from "react-router-dom";

import { Container, Row, Col } from "kit/grid";
import { SelectField } from "kit/form";
import { Button, Icon, Copy, DateFormat, PageWrapper, Title, Currency } from "kit/ui";
import Storage from "core/Storage";
import QueryString from "core/QueryString";
import DateService from "core/DateService";
import { type MemberDetailsStateType } from "@myk";

import MemberContext from "../../../contexts/MemberContext";

import { calculatePaymentDates } from "../../../helpers/loanCalculation.js";
import STORAGE from "../../../constants/STORAGE";
import ROUTES_BASED_ON_CUSTOMER_STATUS from "../../../constants/ROUTES_BASED_ON_CUSTOMER_STATUS";

import RangeSlider from "../../../components/RangeSlider/RangeSlider.component";

import routesObject from "../../../routesObject";

import PhoneCheckForm from "../../Register/view/Register.PhoneCheck.form";

import AgreementModal from "./Home.AgreementModal.view";

import * as styles from "./Home.LoanCalculator.styles.js";


class LoanCalculator extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    hasCustomerLoggedInBefore: !!Storage.Local.get(STORAGE.authMemberBasicDetails)
  };

  // shouldComponentUpdate(nextProps) {
  //   return !_.isEqual(nextProps, this.props);
  // }

  handleAmountChange = amount => {
    const { calculateLoanRequest } = this.props;
    const { paymentDates, paymentDay } = this.props;
    calculateLoanRequest(amount, paymentDates, paymentDay);
  };
  // calculatePaymentDates(installment){}

  handlePaymentDateChange = firstPaymentDay => {
    const { calculateLoanRequest } = this.props;
    const { amount } = this.props;
    const now = (new Date()).toISOString();
    calculateLoanRequest(amount, [ DateService.add(now, firstPaymentDay, "day", "ISO") ], firstPaymentDay);
  };

  handleInstallmentChange = installment => {
    const { calculateLoanRequest, amount, paymentDay } = this.props;
    const paymentDates = calculatePaymentDates(installment);
    calculateLoanRequest(amount, paymentDates, paymentDay);
  };


  AMOUNTS = {
    min: 100,
    max: this.props.fromZeroInterest ? 300 : 600,
    step: 100
  };
  DAYS = {
    min: 2,
    max: 30,
    step: 1
  };
  MONTHS = {
    min: 2,
    max: 4,
    step: 1
  };

  amountOptions = _.map(_.range(this.AMOUNTS.min, this.AMOUNTS.max + this.AMOUNTS.step, this.AMOUNTS.step), item => ({
    id: String(item),
    value: String(item),
    description: Currency.raw(item)
    // description: item
  }));
  daysOptions = _.map(_.range(this.DAYS.min, this.DAYS.max + this.DAYS.step, this.DAYS.step), item => ({
    id: String(item),
    value: String(item),
    description: `${item} días`
    // description: item
  }));
  monthOptions = _.map(_.range(this.MONTHS.min, this.MONTHS.max + this.MONTHS.step, this.MONTHS.step), item => ({
    id: String(item),
    value: String(item),
    description: `${item} meses`
    // description: item
  }));

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps) {}
  // shouldComponentUpdate(nextProps, nextState) {}
  // componentWillUpdate(nextProps, nextState) {}
  // componentDidUpdate(nextProps, nextState) {}
  // componentWillUnmount() {}

  render() {

    const {
      amount,
      paymentDay,
      calculationInProgress,
      viewedInstallment = {},
      installmentAmount,
      paymentDates,
      calculateLoanRequest,
      fromGoogle,
      showAgreement,
      agreement,
      submitPhoneCheckForm
    } = this.props;
    const { customerStatus, customerId } = this.context;
    const { hasCustomerLoggedInBefore } = this.state;

    const phoneCheckFormNecessary = !customerStatus && !hasCustomerLoggedInBefore;
    const formattedExpectedDate = DateService.format(_.get(viewedInstallment, "expectedDate"), "DD/MM/YYYY");

    return (
      <div id="loan-calculator" css={styles.base}>
        <div css={styles.whiteBox}>
          <div css={styles.whiteBoxInner}>


            <div className="amount-holder">
              <div css={styles.field}>
                <label htmlFor="">¿Cuánto necesitas?</label>
                <SelectField.Select
                  id="price"
                  withoutLabel
                  options={this.amountOptions}
                  meta={{
                    focus: true
                  }}
                  input={{
                    value: String(amount),
                    onChange: ({ target }) => this.handleAmountChange(target.value)
                  }} />
              </div>
              <div>
                <RangeSlider
                  id="slider-price"
                  value={String(amount)}
                  step={this.AMOUNTS.step}
                  min={this.AMOUNTS.min}
                  max={this.AMOUNTS.max}
                  onChange={this.handleAmountChange} />
              </div>
              <div css={styles.sliderLabels}>
                <p><Currency value={this.AMOUNTS.min} /></p>
                <p><Currency value={this.AMOUNTS.max} /></p>
              </div>
            </div>

            {fromGoogle ? <div style={{ marginTop: "40px" }}>
              <div css={styles.field}>
                <label htmlFor="">¿En cuántas cuotas quieres devolverlo?</label>
                <SelectField.Select
                  withoutLabel
                  options={this.monthOptions}
                  meta={{
                    focus: true
                  }}
                  input={{
                    value: String(installmentAmount),
                    onChange: ({ target }) => this.handleInstallmentChange(target.value)
                  }} />
              </div>
              <div>
                <RangeSlider
                  value={String(installmentAmount)}
                  step={this.MONTHS.step}
                  min={this.MONTHS.min}
                  max={this.MONTHS.max}
                  onChange={this.handleInstallmentChange} />
              </div>
              <div css={styles.sliderLabels}>
                <p>{this.MONTHS.min} meses</p>
                <p>{this.MONTHS.max} meses</p>
              </div>
            </div> : <div>
              <div css={styles.field}>
                <label htmlFor="">¿Cuando prefieres devolverlo?</label>
                <SelectField.Select
                  id="days"
                  withoutLabel
                  options={this.daysOptions}
                  meta={{
                    focus: true
                  }}
                  input={{
                    value: String(paymentDay),
                    onChange: ({ target }) => this.handlePaymentDateChange(Number(target.value))
                  }} />
              </div>
              <div>
                <RangeSlider
                  id="slider-days"
                  value={String(paymentDay)}
                  step={this.DAYS.step}
                  min={this.DAYS.min}
                  max={this.DAYS.max}
                  onChange={this.handlePaymentDateChange} />
              </div>
              <div css={styles.sliderLabels}>
                <p>{this.DAYS.min} días</p>
                <p>{this.DAYS.max} días</p>
              </div>
            </div>}

          </div>


          <div css={[
            styles.summary,
            (calculationInProgress || !viewedInstallment.paybackAmount) && styles.summaryInProgress
          ]}>
            <p>
              Pagarás <span id="payback-amount"> {viewedInstallment.paybackAmount}€ </span> el <span id="expected-date"> {formattedExpectedDate} </span>
            </p>

            {
              phoneCheckFormNecessary && (
                <div css={styles.whiteBox}>
                  <div css={styles.formHolder}>
                    <p style={{
                      fontSize: 19,
                      textAlign: "center"
                    }}>
                      ¡Empieza con un simple paso!
                    </p>
                    <p style={{
                      textAlign: "center",
                      color: "gray"
                    }}>
                      Ingresa tu número de teléfono e inicia el proceso.
                    </p>
                    <PhoneCheckForm
                      fieldRequired={false}
                      fromHome
                      submitLabel="Quiero myKredit" />
                  </div>
                </div>
              )
            }
            <p css={[ styles.consentText, phoneCheckFormNecessary && styles.consentTextMobile ]}>
              A modo de ejemplo, para un crédito rápido de $7,000 MXN a devolver en 4 meses, el importe de las cuotas sería de $3,415.56 MXN y los intereses serían de $6,602.06 MXN, lo que equivale a una CAT del 2,932%. El plazo mínimo de financiamiento es de 1 mes y el máximo de 4 meses. La CAT anual mínima es de 636% y máxima de 2,932% para este ejemplo representativo. Todos los cálculos son ilustrativos y pueden diferir de las condiciones finales del producto. Para los préstamos, la CAT varía en función del importe y plazo, y se indica en los términos y condiciones de tu solicitud.
            </p>

          </div>

          {
            phoneCheckFormNecessary ? (
              <div className="home-button-holder button-holder">
                <Button
                  fluid
                  className="home-action-button"
                  theme="orange"
                  onClick={submitPhoneCheckForm}>
                  Quiero myKredit
                </Button>
              </div>
            ) : (
              <div className="home-button-holder button-holder">
                <Button
                  className="home-action-button"
                  to={customerStatus ? Storage.Session.get(STORAGE.loanFlowIdFromLoanApply + customerId) ? routesObject.loanApply.url : ROUTES_BASED_ON_CUSTOMER_STATUS()[customerStatus] : routesObject.login.url}
                  as={Link}
                  fluid
                  theme="orange">Quiero myKredit</Button>
              </div>
            )
          }


          <div css={styles.agreementBtnHolder}>
            <Button unstyled onClick={showAgreement}> Más Información </Button>
          </div>

        </div>


        <AgreementModal agreement={agreement} />

      </div>
    );
  }
}


LoanCalculator.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  paymentDay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  paymentDates: PropTypes.array,
  viewedInstallment: PropTypes.object,
  installmentAmount: PropTypes.number,
  calculateLoanRequest: PropTypes.func,
  fromGoogle: PropTypes.bool,
  fromZeroInterest: PropTypes.bool,
  agreement: PropTypes.string,
  calculationInProgress: PropTypes.bool,
  showAgreement: PropTypes.func,
  submitPhoneCheckForm: PropTypes.func
};

LoanCalculator.contextTypes = {
  customerStatus: PropTypes.string,
  customerId: PropTypes.number
};


export default LoanCalculator;
