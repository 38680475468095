// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Container, Row, Col } from "kit/grid";
import { Button, Icon, Copy, DateFormat, PageWrapper, Title } from "kit/ui";

import * as styles from "./Home.HeroText.styles.js";

import heroFs8 from "../assets/hero-fs8.png";


class HeroText extends Component {

  constructor(props, context) {
    super(props, context);
  }

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps) {}
  // shouldComponentUpdate(nextProps, nextState) {}
  // componentWillUpdate(nextProps, nextState) {}
  // componentDidUpdate(nextProps, nextState) {}
  // componentWillUnmount() {}

  render() {
    const { fromZeroInterest } = this.props;

    return (
      fromZeroInterest ? (
        <div>
          <div css={styles.top}>
            <div css={styles.topTitleContainer}>
              <h1 css={styles.zero}>
                0%
              </h1>
              <div css={styles.zeroTitleContainer}>
                <h1 css={styles.zeroHasta}>
                  ¡Hasta 300€!
                </h1>
                <h1 css={styles.zeroTitle}>¡Primer pr&eacute;stamo</h1>
                <h1 css={styles.zeroTitle}>sin intereses!</h1>
              </div>

            </div>

            <h2 css={styles.subTitle}>
              ¡Pagar a tiempo tiene beneficios!
              <br />
              El primer pr&eacute;stamo totalmente sin intereses ni comisiones
              <br />
              si lo devuelves en tiempo.
            </h2>
          </div>
          <div css={styles.bottom}>
            <img
              width="480"
              height="375"
              src={heroFs8}
              alt="Hero Image" />
          </div>
        </div>
      ) : (
        <div>
          <div css={styles.top}>
            <h1 css={styles.title}>
              Préstamos Rápidos en Línea
              <br />
              ¡Recibe hasta $6000 MXN en 10 minutos!
            </h1>
            <h2 css={styles.subTitle}>
              Créditos y Préstamos Rápidos
              <br />
              Pagos mensuales y hasta $42,000 MXN si ya eres cliente.
            </h2>
          </div>
          <div css={styles.bottom}>
            <img
              width="480"
              height="375"
              src={heroFs8}
              alt="Hero Image" />
          </div>
        </div>
      )
    );
  }
}


HeroText.propTypes = {
  fromZeroInterest: PropTypes.bool
};

HeroText.contextTypes = {
};


export default HeroText;
